<template>
	<div class="home">
		
		<div id="content" class="home-content blue-box curved" style="--accent: #f78e1f">
			<svg width="500" height="200" viewBox="0 0 500 200" preserveAspectRatio="none">
				<path d="M0,150 L0,26 Q250,-16 500,26 L500,150 Q250,110 0,150Z" fill="#fef1e4"/>

				<path d="M0,28 L0,24 Q250,-15 500,24 L500,28 Q250,-12 0,28Z" fill="#f78e1f"/>
				<path d="M0,151 L0,147 Q250,108 500,147 L500,151 Q250,111 0,151Z" fill="#f78e1f"/>
			</svg>

            <div class="content-wide centered-banner">
				<p class="banner-title">Student Courses</p>
				<p class="subtitle-small">Our award-winning courses develop skills in playing, improvising, harmonising, listening, understanding and reading.</p>
            </div>
		</div>
		<PhotoRow :set="Math.floor(Math.random() * 3) + 1" style="margin-top: -80px"></PhotoRow>

        <div id="content">
            <CourseSelect @select="$router.push(`/courses/${$event}`)"></CourseSelect>
        </div>            
        
        <div v-for="(course, i) in courses" :key="i" id="course-content">
            <component :is="course.abrv" v-if="selectedCourseIndex == i" @musicwiz="$router.push({ name: 'musicwiz'})" @select="$router.push(`/courses/${$event}`)">
                <p v-if="course.previews" class="course-info-preview" :class="course.abrv.toLowerCase()">Preview the {{ courses[selectedCourseIndex].previews.length > 1 ? "books" : "book" }}{{ !courses[selectedCourseIndex].noaudio ? " & audio" : "" }}</p>

                <div v-if="course.previews" class="column-container books-container">
                    <div v-for="(book, j) in courses[selectedCourseIndex].previews" :key="j" class="book-column" v-bind:class="{
                        'single-book' : (() => {
                            return courses[selectedCourseIndex].previews.length <= 1;
                        })(),
                        'column-4' : courses[selectedCourseIndex].previews.length > 1
                    }">
                        <CourseImage :filename="book.coverFile"></CourseImage>

                        <div>
                            <p class="book-title">{{ book.title }}</p>
                            <p class="book-preview" @click="openModal(track)" v-for="(track, i) in book.tracks" :key="i"><span>play_circle </span>{{ track.name }}</p>
                        </div>
                    </div>
                </div>
            </component>
        </div>

        <Modal v-if="showModal" @close="closeModal">
            <div class="modal-preview-container">
                <p class="preview-title">{{ modalTrack.name }}</p>
                <audio controls v-if="modalFile !== null">
                    <source :src="modalFile" type="audio/mpeg">
                    Your browser does not support the audio tag.
                </audio>

                <div class="pdfContainer">
                    <pdf class="pdfViewer" :src="modalPdfFile" :page="i" :key="i" v-for="i in numPages"></pdf>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import KB from './courses/KB.vue';
import KBPP from './courses/KBPP.vue';
import KBP from './courses/KBP.vue';
import EOK from './courses/EOK.vue';
import JR from './courses/JR.vue';
import PR from './courses/PR.vue';
import ACC from './courses/ACC.vue';
import ACH from './courses/ACH.vue';
import TW from './courses/TW.vue';
import ST from './courses/ST.vue';
import Journal from './courses/Journal.vue';

import Modal from '../components/Modal.vue';
import CourseSelect from '@/components/CourseSelect.vue';
import CourseImage from '@/components/CourseImage.vue';
import PhotoRow from '@/components/PhotoRow.vue';

import pdf from 'vue-pdf';

import courses from '@/courses';

import { storage } from '@/firebase'

export default {
    props: [
        'prefix'
    ],
    components: {
        KB,
        KBPP,
        KBP,
        EOK,
        JR,
        PR,
        ACC,
        ACH,
        TW,
        ST,
        Journal,
        Modal,
        pdf,
        CourseSelect,
        CourseImage,
        PhotoRow
    },
    data() {
		return {
            showModal: false,
            selectedCourseIndex: "kb",
            courses,
            modalTrack: null,
            modalFile: null,
            modalPdfFile: null,
            numPages: undefined
		}
    },
    methods: {
        selectCourse(index) {
            this.selectedCourseIndex = index;

            this.$scrollTo('#course-content', { offset: -120 });
        },
        openModal(track) {
            this.showModal = true;
            this.modalTrack = track;

            this.previewFile(track.file, track.pdfFile);

            document.body.className = "noscroll";
        },
        closeModal() {
            this.showModal = false;
            this.modalTrack = null;
            this.modalFile = null;

            document.body.className = "";
        },
        previewFile(trackFile, pdfFile) {
            const trackStorageRef = storage.ref(`samples/${trackFile}.mp3`);
            trackStorageRef.getDownloadURL().then(url => {
                this.modalFile = url;
            });

            const pdfStorageRef = storage.ref(`samples/${pdfFile}.pdf`);
            pdfStorageRef.getDownloadURL().then(url => {
                this.modalPdfFile = pdf.createLoadingTask(url);
                this.modalPdfFile.promise.then(pdf => {
                    this.numPages = pdf.numPages;
                })
            });
        }
    },
    mounted() {
        if (this.prefix) {
            this.selectCourse(this.prefix);
        }
    },
    computed: {
        console: () => console
    },
    watch: {
        prefix: function(val) {
            if (val == undefined) {
                this.selectedCourseIndex = 'kb';
            } else {
                this.selectCourse(val);
            }
        }
    }
}
</script>