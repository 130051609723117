<template>
    <div>
        <div class="course-banner kb">
            <img src="@/assets/images/EME_titlelogo_KB.png">
            <p>Kinder Beat<span class="reg">&reg;</span></p>
        </div>
        <div id="content" class="course-content">
            <div class="content-wide">
                <p class="course-title kb">Percussion Play</p>
                <p class="course-subtitle">Music Fundamentals | 3 - 4 year olds</p>

                <p class="course-info-bold">
                    Kinder Beat Percussion Play is a vibrant music program for children aged 3 - 4 years. Fundamental music skills are developed in an engaging and stimulating setting with creative extensions used to heighten the learning experience.
                </p>

                <slot></slot>
            </div>
        </div>

        <div id="content" class="course-content-coloured column-container kb-grad">
            <div class="column">           
                <p class="course-section-heading">Focus Activities</p>

                <p class="course-info">
                    Focus activities develop the foundation skills necessary for all musicians.
                </p>

                <p class="course-info">
                    These include:
                </p>

                <ul>
                    <li class="course-info">Rhythm, beat, pulse and metre</li>
                    <li class="course-info">Body percussion</li>
                    <li class="course-info">Musical games and improvisation</li>
                    <li class="course-info">Singing songs, rhymes and solfa/solfege</li>
                    <li class="course-info">Movement, dance and gesture</li>
                    <li class="course-info">Percussion playing – tuned and untuned</li>
                    <li class="course-info">Listening, reflecting and responding</li>
                    <li class="course-info">Story-telling and mime</li>
                    <li class="course-info">Coordinating with a range of props</li>
                    <li class="course-info">Ensemble playing</li>
                    <li class="course-info">Reading, understanding and interacting with iconic and traditional notation</li>
                    <li class="course-info">Piano/Keyboard playing (Level 3)</li>
                </ul>
            </div>

            <div class="column">           
                <p class="course-section-heading">Musical Instruments and Props</p>

                <p class="course-info">
                    Kinder Beat Percussion Play lessons feature a variety of musical instruments and props to stimulate the senses and enrich the learning experience.
                </p>

                <p class="course-info">
                    These include:
                </p>

                <div class="column-container">
                    <div class="column">
                        <ul>
                            <li class="course-info">Kinder Roo &amp; Joey</li>
                            <li class="course-info">The Music Box</li>
                            <li class="course-info">Rainbow Ring</li>
                            <li class="course-info">Parachute</li>
                            <li class="course-info">Streamers</li>
                            <li class="course-info">Hoops</li>
                            <li class="course-info">Scarves</li>
                            <li class="course-info">Balls</li>
                            <li class="course-info">Flash Cards</li>
                            <li class="course-info">Worksheets</li>
                        </ul>
                    </div>

                    <div class="column">
                        <ul>
                            <li class="course-info">Percussion Instruments</li>
                            <li class="course-info">Chime Bars/Tone Bars</li>
                            <li class="course-info">Glockenspiel</li>
                            <li class="course-info">Character Puppets</li>
                            <li class="course-info">Coloured Felt Strips</li>
                            <li class="course-info">Music Staff Mats (Level 3)</li>
                            <li class="course-info">Keyboard Mats (Level 3)</li>
                            <li class="course-info">Piano/Keyboard (Level 3)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="content" class="course-content">
            <div class="course-content-left"> 
                <div style="padding:56.25% 0 0 0;position:relative;" class="video-container">
                    <iframe src="https://player.vimeo.com/video/574745497?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>

                <p class="course-info-bold">
                    Kinder Beat Percussion Play is suitable for both private and group tuition. It is the second instalment in the Kinder Beat program, and may be taught as a stand-alone level or as a follow-on from Music Box Magic (Level 1).
                </p>

                <p class="course-info">
                    Children instantly connect with the vibrant music and creativity in Kinder Beat Percussion Play. The high quality recordings feature world-class arrangements and professional singers, creating that distinctive energy and vibrancy that is unique to Kinder Beat. Kinder Roo is the special character featured throughout this level, ensuring every lesson is an engaging experience for children. 
                </p>

                <p class="course-info">
                    Kinder Beat Percussion Play extends students both musically and cognitively. It engages young children through a whole new world of learning experiences, artistic enrichment and musical challenges.
                </p>

                <p class="course-section-heading">Curriculum</p>

                <p class="course-info">
                    Children enjoy a range of music and ensemble activities in Kinder Beat Percussion Play. It builds on the foundations learnt in Music Box Magic and develops specific rhythm and ensemble skills. Rhythmic awareness begins with movement activities, gestures and body percussion. Props are carefully integrated to both enhance and extend the learning experience. Reading, understanding and playing whole rhythmic units completes a learning sequence that is engaging and thorough. 
                </p>

                <p class="course-info">
                    Other skills that are developed throughout the course include coordination, spatial awareness, memory and language development, plus an awareness of musical concepts such dynamics, tempi and phrasing.
                </p>

                <p class="course-info">
                   Children who successfully complete Kinder Beat Percussion Play advance to <span class="hyper" @click="$emit('select', 'kbp')">Kinder Beat Piano Prep</span>.
                </p>
            </div>

            <div class="course-content-right kb-grad">
                <div>
                    <div class="course-facts-row">
                        <p class="course-facts-title">Age</p>
                        <ul>
                            <li class="course-facts-content">Beginner students aged 3 - 4 years</li>
                        </ul>
                    </div>

                    <div class="course-facts-row">
                        <p class="course-facts-title">Duration</p>
                        <ul>
                            <li class="course-facts-content">6 - 9 months</li>
                        </ul>
                    </div>

                    <div class="course-facts-row">
                        <p class="course-facts-title">Student Materials</p>
                        <ul>
                            <li class="course-facts-content">Student Book</li>
                            <li class="course-facts-content">Audio Files</li>
                            <li class="course-facts-content">Kinder Roo &amp; Joey</li>
                        </ul>
                    </div>

                    <div class="course-facts-row">
                        <p class="course-facts-title">Teacher Materials</p>
                        <ul>
                            <li class="course-facts-content">Teacher Guide</li>
                            <li class="course-facts-content">Teacher Edition Audio Files</li>
                            <li class="course-facts-content">Laminated Flash Cards</li>
                            <li class="course-facts-content">Soft Copy Worksheets</li>
                            <li class="course-facts-content">Certificates of Achievement</li>
                        </ul>
                    </div>

                    <div class="course-facts-row">
                        <p class="course-facts-title">Teaching Aids</p>
                        <ul>
                            <li class="course-facts-content">Assorted Props</li>
                            <li class="course-facts-content">Percussion Instruments</li>
                        </ul>
                    </div>
                </div>

                <div class="musicwiz-promo" @click="$emit('musicwiz')">
                    <img src="@/assets/images/musicwiz.png">
                    <p>Comes with the Encore Music Wiz App!</p>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
export default {
    props: ['previewData']
}
</script>